// Import fonts
@import url(//fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500|Rubik:300,700);
@import url(//use.typekit.net/nqn8uer.css);

$serif-font-family: 'pt-serif-pro-1', 'pt-serif-pro-2',serif;
$title-font-family: 'Rubik','Helvetica Neue', Helvetica, Arial, sans-serif !default;
$sans-font-family: 'IBM Plex Sans','Helvetica Neue', Helvetica, Arial, sans-serif !default;

$white: #f5f5f5;
$black: #282828;
$blue:  rgb(0, 98, 155);
$gray:  #d3cfcf;
$gray-dark: #4a4a4a;
$blue-dark: rgba(0,32,63,1);
$navbar: #009aec;
$body-bg: $black;
$body-color: #f5f5f5;
$border-dark: #d3cfcf;

/* custom vars */
$custom-font-size: 20px;
$primary: $black;
$a: $blue;
$hover: $white;
$visited: $gray;

/* content width */
$content-width: 1024px;

// Import partials.
@import
  "custom/_base",
  "custom/_selection-sharer",
  "custom/_applause"
  ;
// don't forget that last item doesn't have a comma or the whole thing breaks
