body {
	background-color: $body-color;
	max-width: 1024px;
}

/* blog post styling */

article {
	font-family: $sans-font-family;
	max-width: 1024px;
	
	a {
		color: $blue;
	}
	a:hover {
		text-decoration: none;
	}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	font-family: $title-font-family;
	}

	time {
	color:  $gray-dark;
	}

	img {
	width: 100%;
	height: auto;
	}

	p {
		line-height: 1.7em;
		font-family: $sans-font-family;
		font-weight: 400;
		font-size: 1em;
	}

	.video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
		min-width: 320px;
    height: 0;
    overflow: hidden;
    margin-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;

	iframe, object, embed {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	}
}

blockquote {
	color: $body-bg;
	line-height: 1.7em;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	margin-top: 15px;
	font-weight: 400;
	border-left: 4px solid $gray;
	padding-left: 10px;
	font-family: $sans-font-family;
	font-style: italic;
	}

q  {
	font-size: 175%;
	line-height: 1.5em;
	padding: 20px;
	margin-left: -15px;
	margin-right: -15px;
	font-weight: 400;
	font-family: $title-font-family;
	background-color: $blue; 
	color: $body-color;
	display: block;
	border: none;
	min-height: 150px;
	align-content: center;
}

cite {
	quotes: ‘„’ ‘“’;
	}
}

hr {
	display:block;
	width: 50%;
	margin: 30px auto 30px auto;
	border:0;
	border-top:2px solid $body-color;
}

/* footer */

footer {
	font-family: $sans-font-family;
}

/* Other classes and elements */

.tags {
	text-transform: uppercase;
}

.table {
	font-family: $sans-font-family;
  @extend .table;
}

.td {
	font-weight: 400;
}

.btn {
		background-color: $blue;
		font-family: $sans-font-family;
	}

.card {
	font-family: $sans-font-family;
	font-weight: 300;
	a {
	text-decoration: none;
/*	color: #3f3f3f; */
	}

	a:visited {
/*	 color: #222733; */
	 text-decoration: none;
	}

	h2 {
	font-family: $title-font-family;
	font-weight: 700;
	font-size: medium;
	text-transform: uppercase;
	color: $blue;
	}

	.btn {
	font-family: $title-font-family;
	}
	.card-text {
		font-size: 1em;
	}
}

/* Nav */

.navbar {
	font-family: $sans-font-family;

	.nav-special {
		max-width: $content-width;
		margin-left: auto;
		margin-right: auto;
	}
	.nav-link {
		font-weight: 400;
	}
}

.navbar-brand {
		font-family: $title-font-family;
		font-size: 3em;
	}

.navbar-dark {
	background-color: $blue;
	border-bottom: solid 6px $gray;
}

.ald-logo {
		background: url(/assets/images/a-little-guide-type.svg);		
		display: inline-block;
        text-indent: -9999px;
	    width: 250px;
	    height: 50px;
		background-repeat: no-repeat;
  }

.pagination {
  font-size: 1em;
  display: block;
  width: auto;
  overflow: hidden;
  font-family: $sans-font-family;

 a {
  width: 50%;
  margin: 1em 0;
	text-decoration: none;
}
}

.socialicons {
font-size: 2em;
letter-spacing: .2em;
text-decoration: none;

a {
color: $gray-dark;
}

a:hover {
color: $blue;
	}
}

/* Blog styling */

/* Pagination */

/* .blog-pagination {
  margin-bottom: 4rem;
  > .btn {
    border-radius: 2rem;
  }
}

/* Blog posts */
.blog {

.post{
	margin-bottom: 4rem;
  line-height: 1.7em;
  font-weight: 300;
  font-size: 1em;
  font-family: $sans-font-family;
}
.post-title {
		margin-bottom: .25rem;
	  font-size: 2rem;
	  font-weight: 700;
		}

.post-meta {
			font-family: $title-font-family;
			font-weight: 700;


	.reading {
					color: $gray;
					padding-left: 10px;
					text-transform: uppercase;
			}

	}
}

.media img{
	max-width: 100px;
}

.card-img-top img {
width: 100%;
height: auto;
}

.ais-search-box {
	max-width: 100%;
	margin-bottom: 15px;
  }
  
  .post-item {
	margin-bottom: 30px;
	font-family: $sans-font-family;
  }
  
  .post-link .ais-Highlight {
	color: #111;
	font-style: normal;
	text-decoration: underline;
  }
  
  .post-breadcrumbs {
	color: #424242;
	display: block;
  }
  
  .post-breadcrumb {
	font-size: 18px;
	color: #424242;
  
	.ais-Highlight {
	  font-weight: bold;
	  font-style: normal;
	}
  }
  
  .post-snippet {
	.ais-Highlight {
	  color: #2a7ae2;
	  font-style: normal;
	  font-weight: bold;
	}
  }

  .search-image {
	  max-width: 200px;
  }

  .search-text {
	  font-family: $title-font-family;
  }

/* Here is some extra stuff */
    
div.pin-item {
  border-bottom: solid 1px#dcdcdc;
  padding-top: 5px;
  margin-top: 5px; 
}

span.pin-description {
      font-size: 14px;
   }

/* flat button test */

.flat-butt {
	display: inline-block;
	margin: 0 5px;
	border-radius: 6px;
	font-size: 16.5px;
	padding: 12px 20px;
	border: 0;
	background: #34495E;
	color: #FFF;
	cursor: pointer;
	outline: 0;
  
	&:hover {
	  background: #005580;
	}
  }
  
  .flat-primary-butt {
	background: #1abc9c;
  
	&:hover {
	  background: #2fe2bf;
	}
  }
  
  .flat-info-butt {
	background: #3498db;
  
	&:hover {
	  background: #5dade2;
	}
  }
  
  .flat-danger-butt {
	background: #e74c3c;
  
	&:hover {
	  background: #ec7063;
	}
  }
  
  .flat-border-butt {
	padding: 8px 20px;
	border: 4px solid #2C3E50;
  
	&:hover {
	  border: 4px solid #2C3E50;
	}
  }
  
  .flat-primary-border-butt {
	border-color: #16A085;
  
	&:hover {
	  border-color: #27AE60;
	}
  }
  
  .flat-info-border-butt {
	border-color: #2980B9;
  
	&:hover {
	  border-color: #2980B9;
	}
  }
  
  .flat-danger-border-butt {
	border-color: #C0392B;
  
	&:hover {
	  border-color: #C0392B;
	}
  }
  
  .flat-double-butt {
	padding: 8px 20px;
	border: 3px solid #FFF;
	box-shadow: #34495E 0px 0px 0px 3px;
  
	&:hover {
	  box-shadow: #005580 0px 0px 0px 3px;
	}
  }
  
  .flat-primary-double-butt {
	box-shadow: #1abc9c 0px 0px 0px 3px;
  
	&:hover {
	  box-shadow: #2fe2bf 0px 0px 0px 3px;
	}
  }
  
  .flat-info-double-butt {
	box-shadow: #3498db 0px 0px 0px 3px;
  
	&:hover {
	  box-shadow: #5dade2 0px 0px 0px 3px;
	}
  }
  
  .flat-danger-double-butt {
	box-shadow: #e74c3c 0px 0px 0px 3px;
  
	&:hover {
	  box-shadow: #ec7063 0px 0px 0px 3px;
	}
  }
  
  .flat-underline-butt {
	text-decoration: underline;
  }
  
  .flat-grad-butt {
	background-image: linear-gradient(#34495E 50%, #2C3E50 50%);
  }
  
  .flat-primary-grad-butt {
	background-image: linear-gradient(#1ABC9C 50%, #16A085 50%);
  }
  
  .flat-info-grad-butt {
	background-image: linear-gradient(#3498DB 50%, #2980B9 50%);
  }
  
  .flat-danger-grad-butt {
	background-image: linear-gradient(#E74C3C 50%, #C0392B 50%);
  }
  
  .flat-inner-butt {
	padding: 11px 0px;
	box-shadow: inset #2C3E50 -3px -3px 0px;
  }
  
  .flat-primary-inner-butt {
	background: #1ABC9C;
	box-shadow: inset #16A085 -3px -3px 0px;
  }
  
  .flat-info-inner-butt {
	box-shadow: inset #2980B9 -3px -3px 0px;
  }
  
  .flat-danger-inner-butt {
	box-shadow: inset #C0392B -3px -3px 0px;
  }
  
  .flat-outer-butt {
	padding: 11px 0px;
	box-shadow: #BDC3C7 2px 2px 0px;
  }